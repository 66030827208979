import React from "react";

export default function Labels() {
  return (
    <div className="Labels">
      <div>TUNE</div>
      <div>ARTIST</div>
      <div>KEY</div>
      <div>TUNING</div>
    </div>
  );
}
